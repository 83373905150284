<template>
  <v-container fluid>
    <v-row class="SplashHeaderBackground">
      <v-col cols="12">
        <div class="Center h3">About</div>
        <div class="Center centerText my-5 h1">Riley Parada</div>
        <div class="Center centerText h3 font-italic mb-15">
          Product Manager, Tinkerer, Always Curious.
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="Section">
        <div class="h2 d-flex justify-center mt-15 mb-5">Specialties</div>
        <div class="h5 d-flex justify-center">
          Some skills I'm best suited for.
        </div>
      </v-col>
    </v-row>
    <v-row class="productIdeaSections">
      <v-col cols="12" xs="6" sm="3" md="3" xl="3">
        <v-card min-height="300">
          <div class="productIdeaSectionsItems h3 HeaderUnderline">
            Product Strategy
          </div>
          <div class="textSection pa-5 h5">
            Helped set high-level strategy for a variety of products, including
            helping set the long term product vision, roadmap and business model
            for both Parallel and The Herb Cab.
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" xs="6" sm="3" md="3" xl="3">
        <v-card min-height="300">
          <div class="productIdeaSectionsItems h3 HeaderUnderline">
            Product Discovery
          </div>
          <div class="textSection pa-5 h5">
            For both Parallel and The Herb Cab, the initial discovery of the
            product was led by myself along with a team of developers. Find out
            more how I came to the idea of these products in the projects page.
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" xs="6" sm="3" md="3" xl="3">
        <v-card min-height="300"
          ><div class="productIdeaSectionsItems h3 HeaderUnderline">
            Product Management
          </div>
          <div class="textSection pa-5 h5">
            From creating product roadmaps, speaking to users to understand
            their needs, to working with developers and designers. I can
            effectively manage products and features from ideation to execution.
          </div>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <!-- Desktop -->
      <v-col
        cols="4"
        xs="4"
        offset="2"
        class="align-items-centers"
        v-if="$vuetify.breakpoint.mdAndUp"
      >
        <div class="h3 d-flex justify-center mb-10">Bio</div>
        <div class="h5 d-flex justify-center mb-10">
          At the end of the day, my goal is to solve problems for people. It's
          what drives my interests, and how I frame my ideas. As a Product
          Manager during the day and a Web Developer on my free time, I
          understand what's required to build products that people love.
        </div>
      </v-col>
      <v-col cols="4" xs="4" v-if="$vuetify.breakpoint.mdAndUp">
        <div class="align-items-centers">
          <div class="h3 d-flex justify-center mb-10">Technical Skills</div>
          <div class="h5 d-flex justify-center mb-10">
            <v-list dense>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>mdi-vuejs</v-icon>
                </v-list-item-icon>
                Vue JS</v-list-item
              >
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>mdi-language-javascript</v-icon>
                </v-list-item-icon>
                Vanilla JS</v-list-item
              >
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>mdi-language-html5</v-icon>
                </v-list-item-icon>
                HTML</v-list-item
              >
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>mdi-language-css3</v-icon>
                </v-list-item-icon>
                CSS 3</v-list-item
              >
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>mdi-database-search</v-icon>
                </v-list-item-icon>
                SQL</v-list-item
              >
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>mdi-language-java</v-icon>
                </v-list-item-icon>
                Java</v-list-item
              >
            </v-list>
          </div>
        </div>
      </v-col>
      <!-- Mobile -->
      <template v-else>
        <v-col cols="12" class="align-items-centers pa-10">
          <div class="h3 d-flex justify-center mb-10">Bio</div>
          <div class="h5 d-flex justify-center mb-10">
            At the end of the day, my goal is to solve problems for people. It's
            what drives my interests, and how I frame my ideas. As a Product
            Manager during the day and a Web Developer on my free time, I
            understand what's required to build products that people love.
          </div>
        </v-col>
        <v-col cols="12">
          <div class="align-items-centers">
            <div class="h3 d-flex justify-center mb-10">Technical Skills</div>
            <div class="h5 d-flex justify-center mb-10">
              <v-list>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon>mdi-vuejs</v-icon>
                  </v-list-item-icon>
                  Vue JS</v-list-item
                >
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon>mdi-language-javascript</v-icon>
                  </v-list-item-icon>
                  Vanilla JS</v-list-item
                >
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon>mdi-language-html5</v-icon>
                  </v-list-item-icon>
                  HTML</v-list-item
                >
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon>mdi-language-css3</v-icon>
                  </v-list-item-icon>
                  CSS 3</v-list-item
                >
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon>mdi-database-search</v-icon>
                  </v-list-item-icon>
                  SQL</v-list-item
                >
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon>mdi-language-java</v-icon>
                  </v-list-item-icon>
                  Java</v-list-item
                >
              </v-list>
            </div>
          </div>
        </v-col>
      </template>
    </v-row>
    <template>
      <v-row>
        <v-col class="Section">
          <div class="align-items-centers">
            <div class="h3 d-flex justify-center mb-7">Let's Connect</div>
            <div class="h5 d-flex justify-center mb-10 centerText">
              Feel free to reach out.<br />
              I'm always open to discussing work, side projects, opportunities,
              or just to chat.<br />
            </div>
          </div>
        </v-col>
      </v-row>
      <div class="d-flex justify-center">
        <router-link to="Contact" class="empty">
          <div class="dot d-flex justify-center cursor">
            <v-icon class="CenterIcon" x-large dark>mail </v-icon>
          </div>
        </router-link>
      </div>
    </template>
  </v-container>
</template>

<script>
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  setup() {},
});
</script>
<style scoped>
.SplashHeaderBackground {
  background-color: #edf6f9;
  display: flex;
  justify-content: center;
  align-items: center;
}
.h1 {
  font-size: 4.5em;
  color: #ff6b6b;
  font-family: "Raleway", "sans-serif";
  font-weight: 800;
}
.h2 {
  font-size: 3em;
  color: #1a535c;
  font-family: "Raleway", "sans-serif";
  font-weight: 600;
}

.h3 {
  font-size: 2em;
  color: #1a535c;
  font-family: "Raleway", "sans-serif";
  font-weight: 600;
}
.h5 {
  font-size: 1em;
  color: #1a535c;
  font-family: "Raleway", "sans-serif";
  font-weight: 400;
}

.site-background {
  background-color: #edf6f9;
}

.cursor:hover {
  cursor: pointer;
}

.productIdeaSections {
  justify-content: center;
  padding-top: 50px;
  padding-bottom: 150px;
}

.productIdeaSectionsItems {
  padding: 15px;
}

.textSection {
  justify-content: left;
  align-items: center;
}
.centerText {
  text-align: center;
}
.dot {
  height: 130px;
  width: 130px;
  background-color: #ff6b6b;
  border-radius: 50%;
  display: flex;
}
.CenterIcon {
  display: flex;
}
.empty {
  color: transparent;
  text-decoration: none;
}
.Center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.HeaderUnderline:after {
  content: "";
  display: block;
  width: 32px;
  padding-top: 3px;
  border-bottom: 2px solid #ff6b6b;
}
.Section {
  padding-top: 50px;
  padding-bottom: 50px;
}
</style>

